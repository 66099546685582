import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

import Layout from '../components/Layout'

import { useRecoilValue } from 'recoil'
import { recoilAppReady } from '../recoil/app'

import useTranslation from '../hooks/useTranslation'

const LanguageSelectorTemplate = () => {
  const ready = useRecoilValue(recoilAppReady)
  const {lang} = useTranslation()
  const {hash} = useLocation()
  useEffect(() => {
    if (ready && !hash)
      navigate(`/${lang}/`)
    else if (ready && hash)
      if (hash.substring(0, 8) === '#qrc-cp-')
        navigate(`/${lang}/community-prescribers-qr-code-links/${hash}`)
      else
        navigate(`/${lang}/qr-code-links/${hash}`)
  }, [ready, lang, hash])
  return <Layout />
}

export default LanguageSelectorTemplate
